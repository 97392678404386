import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import ArrowRight from "../components/svg/arrowRight"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Cta from "../components/cta"

const GbcaPage = () => (
    <Layout>
        <Seo 
            title="Django Web Development case study" 
            description="Web development case study - the web design and development services completed for this Australian nonprofit."
        />

        <Hero
            h1="Python Django web development case study"  
            h2="Green Building Council of Australia"
        />

        <section className="bg-noise gbca section-pad-sm">
            <div className="container-lg" id="anchor">
                <div className="row">
                    <StaticImage 
                        src="../images/gbca-main.png"
                        alt="Screens of the Green Building Council of Australia website"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>
        </section>

        <section className="section-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>GBCA rate, educate and advocate to lead the sustainable transformation of the built environment.</p>
                                    <p>GBCA approached Launch Lab to take over the maintenance and development of their Django website and content management system.</p>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/web-design/" className="btn btn-lg btn-line">Web design</Link>
                                    <Link to="/django-developer-sydney-canberra/" className="btn btn-lg btn-line">Python / Django development</Link>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h4>The work</h4>
                                    <p>Launch Lab took over the maintenance and ongoing development of the GBCA website from another <Link to="/">web development company in Sydney</Link>.</p>
                                    <p>The website is built on the Django web development framework and makes use of FeinCMS.</p>
                                    <p>Since taking over support of the website Launch Lab has implemented new functionality and has re-designed the website.</p>
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>View the Green Building Council of Australia website.</p>
                                    <a href="https://new.gbca.org.au/" className="btn btn-lg btn-purple">View website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        
        <Cta 
            text="Get an obligation free web design and / or web app development quote from an experienced local Australian development team."
        />
    </Layout>
)

export default GbcaPage